import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import './index.css';
import HomePage from './pages/HomePage.tsx';
import { lazy, Suspense, useCallback, useEffect, useRef } from 'react';
import MessageSheet from '@/components/MessageSheet.tsx';
import { getAuthService, useAuthService } from '@/service/AuthService.ts';
import { resetAllStores } from '@/store/create-disposable-store.ts';
import { getConferenceService, initializeConferenceService } from '@/service/ConferenceService.ts';
import { Toaster } from '@/components/ui/sonner.tsx';
import { createConferenceSDK } from '@/lib/conference-sdk.ts';
import { initializeDeviceService } from '@/service/DeviceService.ts';

const AuthPage = lazy(() => import('@/pages/AuthPage.tsx'));
const HistoryPage = lazy(() => import('@/pages/HistoryPage.tsx'));
const VersionPage = lazy(() => import('@/pages/VersionPage.tsx'));
const ConferencePage = lazy(() => import('@/pages/ConferencePage.tsx'));

const DEFAULT_FALLBACK = <MessageSheet message="Loading..." />;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<HomePage />} />

      <Route
        path="auth"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <AuthPage />
          </Suspense>
        }
      />

      <Route
        path="history"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <HistoryPage />
          </Suspense>
        }
      />

      <Route
        path=":roomId"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <ConferencePage />
          </Suspense>
        }
      />

      <Route
        path="version"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <VersionPage />
          </Suspense>
        }
      />
    </Route>
  )
);

function App() {
  const authService = useAuthService();
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const scheduleTokenRefresh = useCallback((): void => {
    const expIn: number | null = authService.getAccessTokenExpiresIn();
    if (expIn) {
      const timeout = expIn > 5000 ? expIn - 5000 : 0; // 5 seconds before expiry
      timeoutIdRef.current = setTimeout(async () => {
        await authService.refresh();
        scheduleTokenRefresh();
      }, timeout);
    }
  }, [authService]);

  useEffect(() => {
    (async () => {
      resetAllStores();
      scheduleTokenRefresh();

      const sdk = await createConferenceSDK();
      initializeDeviceService(sdk);
      initializeConferenceService(sdk);

      return () => {
        timeoutIdRef.current && clearTimeout(timeoutIdRef.current);
        getConferenceService()?.closeSession();
        resetAllStores();
      };
    })();
  }, [scheduleTokenRefresh]);

  // Reload the page when the user returns from another tab
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener('load', async () => {
      const authService = getAuthService();
      await authService.getUser();
    });
  }, []);

  return (
    <>
      <RouterProvider router={router} />
      <Toaster position="top-right" />
    </>
  );
}

export default App;
